.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    border: 1px solid   gray;
    padding: 20px;
    width: 300px;
    max-width: 90%;
  
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .modal-message {
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-button {
    padding: 8px 16px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-cancel {
    background-color: #ccc;
  }
  
  .modal-confirm {
    background-color: #e53e3e;
    color: white;
  }
  