/* Table.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.pagination > * {
  margin: 0.125rem;
}

.pagination .page-link {
  @apply px-3 py-2 rounded bg-gray-200 text-gray-700 border border-gray-300 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-blue-300;
}

.pagination .page-item.active .page-link {
  @apply bg-blue-500 text-white hover:bg-blue-600;
}

.pagination .page-item.disabled .page-link {
  @apply opacity-50 cursor-not-allowed;
}

.pagination .previous .page-link,
.pagination .next .page-link {
  @apply px-3 py-2 rounded bg-blue-500 text-white border border-blue-600 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300;
}
.pagination .page-link {
  @apply px-3 py-2 rounded hover:bg-gray-200 focus:outline-none focus:ring focus:ring-blue-300;
}

.pagination .page-item.active .page-link {
  @apply bg-blue-500 text-white hover:bg-blue-600;
}

/* Style for navigation buttons */
.pagination .previous .page-link,
.pagination .next .page-link {
  @apply px-3 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300;
}

/* Style for disabled buttons */
.pagination .page-item.disabled .page-link {
  @apply opacity-50 cursor-not-allowed;
}
/* Style for active page number */
.pagination .active a {
  @apply bg-blue-500 text-white hover:bg-blue-600;
}
.pagination .previous {
  @apply p-2 px-3 mr-2 hover:bg-blue-600 bg-blue-500 text-white rounded;
}
.pagination .next {
  @apply p-2 px-3 ml-2 hover:bg-blue-600 bg-blue-500 text-white rounded;
}
