.custom-slider .slick-next {
  color: blue; /* Change this to your desired arrow color */
}

.custom-slider .slick-prev {
  color: green; /* Change this to your desired arrow color */
}

/* Custom CSS for card flip animation */
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.7s;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
}

@keyframes ping-once {
  0%,
  100% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(2);
    opacity: 0.5;
  }
}

.animate-ping-custom {
  animation: ping-once 5s infinite;
}

.stop-animation {
  animation: none !important;
}
